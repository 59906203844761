import Script from "next/script";

/**
 * Renders a TrustPilot widget
 */
export default function TrustPilotWidget() {
  return (
    <>
      <Script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" />
      <div
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="53aa8807dec7e10d38f59f32"
        data-businessunit-id="57dff8180000ff000594e8bb" // cspell:disable-line
        data-style-height="150px"
        data-style-width="100%"
        data-theme="dark"
        data-font-family="Source Sans Pro"
      >
        <a
          href="https://www.trustpilot.com/review/pivothealth.com"
          target="_blank"
          rel="noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </>
  );
}
