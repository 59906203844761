import BuilderText from "./BuilderText";
import useExperiment from "./useExperiment";

/**
 * The first value is the variant key/ID (variant.value returned from Amplitude)
 * and the second is the content that should be show if the the variant of the user
 * matches the variant key.
 */
export type VariantKV = { variantKey: string; variantContent: string };

/** Props for `ExperimentText`. These should be registered as inputs using `Builder.registerComponent`. */
export interface ExperimentTextProps {
  /** The unique name of the Amplitude experiment (called "Feature Flag" in Amplitude's UI) */
  experimentKey: string;

  /** The value of the default variant to show if something goes wrong while getting the assigned variant. */
  defaultVariant: string;

  /**
   * Key-value pairs defining the content that should be shown for each variant (identified by the "value" field
   * in Amplitude's UI, but serving as the key here).
   */
  variants: VariantKV[];
}

/**
 * Get content to display for a variant.
 *
 * @param variants - The variant data configured in Builder.io
 * @param defaultVariant - The variant to use if the user's assigned variant cannot be determined.
 * @param variantValue - The user's assigned variant.
 */
function getContent(
  variants: VariantKV[],
  defaultVariant: string,
  variantValue?: string
): string {
  let userVariant: VariantKV | undefined = undefined;
  if (variantValue) {
    userVariant = variants.find(
      (variant) => variant.variantKey === variantValue
    );
  }

  if (!userVariant) {
    userVariant = variants.find(
      (variant) => variant.variantKey === defaultVariant
    );
  }

  return userVariant?.variantContent ?? "";
}

/**
 * Displays different text content based on the user's variant in an Amplitude experiment.
 * Intended for use with Builder.io.
 *
 * The component initially renders with just an empty string until the experiment data has
 * finished loading. Once it is loaded, the content is chosen from the `variants` prop
 * using the `experimentKey`. If the experiment data has finished loading but the variant
 * for this experiment cannot be found, then the content for `defaultVariant` is rendered
 * instead.
 *
 * Because this component renders client-side, there is a brief moment where the content
 * is blank before it pops in. In most cases, this should be fast, but it is worth keeping
 * in mind if the content is above the fold.
 *
 * NOTE: useAmplitudeStart must be called during page load for the Amplitude data to be available.
 *
 * @see {@link https://healthcareinc.atlassian.net/wiki/x/Y4CNug | RFC}
 */
export default function ExperimentText({
  experimentKey,
  defaultVariant,
  variants,
}: ExperimentTextProps) {
  const variantState = useExperiment({ experimentKey });
  const content = variantState.loaded
    ? getContent(variants, defaultVariant, variantState.variant?.value)
    : "";

  return <BuilderText text={content} />;
}
