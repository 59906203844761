import { Typography } from "@mui/material";
import { BuilderDefaultComponentProps } from "./BuilderH1";

export default function BuilderH4(props: BuilderDefaultComponentProps) {
  return (
    <Typography
      component="h4"
      sx={{
        all: "unset",
      }}
    >
      {props.text}
    </Typography>
  );
}
