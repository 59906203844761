import { Box, Typography } from "@mui/material";
import Script from "next/script";
import React from "react";

/** Props for the ConnectMe initializer */
export interface ConnectMeProps {
  /** The phone number of the user desiring a call */
  userPhoneNumber: string;
  /** The destination phone number from Invoca */
  invocaPhoneNumber: string;
  /** The ConnectMe module id */
  moduleId: number;
  /** The conversion id */
  conversionId: string;
}

/**
 * Initializes a ConnectMe call
 * Note: initializes the call immediately upon rendering, so this view should only
 *   be rendered in React after a user interacts with a page
 * @param props - See ConnectMeProps
 * @returns The ConnectMe script that will fire to initialize a call
 */
export default function ConnectMe(props: ConnectMeProps) {
  const url = process.env.NEXT_PUBLIC_CONNECT_ME_URL;
  if (!url)
    throw new Error(
      "NEXT_PUBLIC_CONNECT_ME_URL is not defined.  Add it to the dotenv file."
    );

  return (
    <>
      <Typography component="div">
        <Box
          className="g3cm_holder"
          data-module={props.moduleId}
          data-showoninit={true}
          data-calloninit={true}
          data-conversionid={props.conversionId}
          data-destination={props.invocaPhoneNumber}
          data-destinationext=""
          data-followup=""
          style={{
            display: "none",
          }}
        >
          Your phone will ring in 5 seconds...
        </Box>
      </Typography>
      <Script id="connect-me">
        {`
                window.g3cm = window.g3cm || function () { (g3cm.q = g3cm.q || []).push(arguments) };
                g3cm('addholder', { moduleId: ${props.moduleId}, destination: "${props.invocaPhoneNumber}", conversionId: "${props.conversionId}" });
                g3cm('loadjquery', 'true');
                g3cm('phonenumber', '${props.userPhoneNumber}');
                g3cm('send', 'init');
                `}
      </Script>
      <Script
        src="https://api.connectstreams.com/js/connectme-v3.min.js"
        async
      />
    </>
  );
}
