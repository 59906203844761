import type { ExperimentClient } from "@amplitude/experiment-js-client";
import { Experiment } from "@amplitude/experiment-js-client";
import { trackExperimentView } from "@hc-frontend/third-party-snowplow";
import React from "react";

/**
 * Initializes the Amplitude Experiment client for a user and returns it
 * for use in a component.
 *
 * For a given instance name, the `ExperimentClient` is a singleton, so this hook can be safely called from
 * multiple components as often as necessary.
 *
 * @param instanceName - Name of the `ExperimentClient` instance. Subsequent initializations using the same name always return the same instance.
 */
export default function useAmplitudeExperimentClient(
  instanceName?: string
): ExperimentClient | undefined {
  const enabled = process.env.NEXT_PUBLIC_ENABLE_AMPLITUDE === "true";
  const DEPLOYMENT_KEY =
    process.env.NEXT_PUBLIC_AMPLITUDE_EXPERIMENT_DEPLOYMENT_API_KEY;
  const [client, setClient] = React.useState<ExperimentClient | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (enabled && DEPLOYMENT_KEY) {
      const experimentClient = Experiment.initialize(DEPLOYMENT_KEY, {
        instanceName: instanceName || "default",
        automaticExposureTracking: false,
        // When a part of the page changes based on an experiment, an exposure event
        // should be fired using this function (via experimentClient.track(...)).
        exposureTrackingProvider: {
          /* c8 ignore next 13 */
          track: (exposure) => {
            if (!exposure.variant) {
              throw new TypeError(
                "exposure.variant must be defined to track exposures"
              );
            }

            trackExperimentView({
              experimentName: exposure.flag_key,
              variantName: exposure.variant,
              splitSource: "amplitude",
            });
          },
        },
      });
      setClient(experimentClient);
    }
  }, [setClient, enabled, DEPLOYMENT_KEY, instanceName]);

  return client;
}
