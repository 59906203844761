import { Link, Typography } from "@mui/material";

export default function KFFSubsidyCalculator() {
  return (
    <>
      <iframe
        style={{
          border: 0,
          flexGrow: 1,
          margin: "auto",
          padding: 0,
        }}
        id="nnc-widget"
        src="https://www.kff.org/interactive/subsidy-calculator-frameless/"
        width="800px"
        height="1550px"
      />
      <Typography
        sx={{
          width: "800px",
          margin: "auto",
          fontFamily: "DM Sans",
          fontSize: "12px",
          color: "#323950",
        }}
        component="div"
      >
        Centers for Medicare & Medicaid Services. (2023). 2024 Health Insurance
        Exchange Public Use Files (Specific data file name – e.g., Plan
        Attributes PUF) [Data file and code book]. Retrieved from&nbsp;
        <Link href="http://www.cms.gov/CCIIO/Resources/Data-Resources/marketplace-puf.html">
          http://www.cms.gov/CCIIO/Resources/Data-Resources/marketplace-puf.html
        </Link>
      </Typography>
    </>
  );
}
