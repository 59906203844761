import { BuilderElement } from "@builder.io/react";
import { BuilderBlock } from "@builder.io/react/dist/types/src/components/builder-block.component";
import HeaderDesktop from "./HeaderDesktop";
import { Box } from "@mui/material";
import HeaderMobile from "./HeaderMobile";

export interface HeaderProps {
  tabs: {
    label: string;
    content: BuilderElement[];
    isExpandable: boolean;
    linkURL: string;
  }[];
  backgroundColor: string;
  builderBlock?: BuilderBlock;
}

export default function Header(props: HeaderProps) {
  return (
    <>
      <Box
        sx={{
          display: "block",
          "@media screen and (max-width: 991px)": {
            display: "none",
          },
        }}
      >
        <HeaderDesktop {...props} />
      </Box>
      <Box
        sx={{
          display: "none",
          "@media screen and (max-width: 991px)": {
            display: "block",
          },
        }}
      >
        <HeaderMobile {...props} />
      </Box>
    </>
  );
}
