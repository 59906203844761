import { Builder, withChildren } from "@builder.io/react";
import Expando from "./Expando";
import CheckIcon from "./CheckIcon";
import { dtcTheme } from "../theme/theme";
import PreviousQuoteBanner from "./PreviousQuoteBanner";
import ScrollToTop from "./ScrollToTop";
import ZipCodeWidget from "./zipcode/ZipCodeWidget";
import ThinZipCodeWidget from "./zipcode/ThinZipCodeWidget";
import TrustPilotWidget from "./TrustPilotWidget";
import QuotesCarousel from "./QuotesCarousel";
import TypewriterText from "./TypewriterText";
import dynamic from "next/dynamic";
import ButtonBox from "./ButtonBox";
import BeforeYouGoModal from "./BeforeYouGoModal";
import HeroImage from "./HeroImage";
import ButtonBoxContainer from "./ButtonBoxContainer";
import BuilderH1 from "./builder/BuilderH1";
import BuilderH3 from "./builder/BuilderH3";
import BuilderH2 from "./builder/BuilderH2";
import BuilderText from "./builder/BuilderText";
import BuilderH4 from "./builder/BuilderH4";
import BuilderH5 from "./builder/BuilderH5";
import BuilderH6 from "./builder/BuilderH6";
import ExperimentText from "./builder/ExperimentText";
import ExperimentHeader1 from "./builder/ExperimentHeader1";
import ExperimentHeader2 from "./builder/ExperimentHeader2";
import ExperimentHeader3 from "./builder/ExperimentHeader3";
import ExperimentHeader4 from "./builder/ExperimentHeader4";
import ExperimentHeader5 from "./builder/ExperimentHeader5";
import ExperimentHeader6 from "./builder/ExperimentHeader6";
import VariantBlock from "./builder/VariantBlock";
import {
  builderExperimentHeaderInputs,
  baseDefaultStyle,
} from "./builderComponentOptions";
import ExperimentImage from "./builder/ExperimentImage";
import HeaderDropdown from "./HeaderDropdown";
import KFFSubsidyCalculator from "./KFFSubsidyCalculator";
import Header from "./Header";
import WordPressRelatedContent from "./WordPressRelatedContent";
import FAQAccordion from "./FAQAccordion";
import OutlinedAccordion from "./OutlinedAccordion";
import HeroDropdown from "./HeroDropdown";

const VariantBlockWithBuilderChildren = withChildren(VariantBlock);

export function registerBuilderComponents() {
  Builder.registerComponent(Expando, {
    name: "Expando",
    inputs: [
      { name: "title", type: "string" },
      { name: "content", type: "string" },
      { name: "defaultExpanded", type: "boolean", defaultValue: false },
    ],
  });

  Builder.registerComponent(CheckIcon, {
    name: "Check Icon",
    inputs: [
      {
        name: "color",
        type: "string",
        defaultValue: dtcTheme.palette.success.main,
      },
    ],
  });

  Builder.registerComponent(PreviousQuoteBanner, {
    name: "Previous Quote Banner",
    inputs: [{ name: "hasPreviousQuote", type: "boolean", defaultValue: true }],
  });

  Builder.registerComponent(ScrollToTop, {
    name: "Scroll To Top",
  });

  Builder.registerComponent(ZipCodeWidget, {
    name: "Zip Code",
  });

  Builder.registerComponent(ThinZipCodeWidget, {
    name: "Thin Zip Code",
  });

  Builder.registerComponent(TrustPilotWidget, {
    name: "TrustPilot Widget",
  });

  Builder.registerComponent(QuotesCarousel, {
    name: "Quotes Carousel",
    inputs: [
      {
        name: "quoteEntries",
        type: "list",
        subFields: [
          { name: "content", type: "string" },
          { name: "byline", type: "string" },
        ],
      },
      {
        name: "seePlansUrl",
        type: "string",
        defaultValue: "/census",
      },
    ],
  });

  Builder.registerComponent(TypewriterText, {
    name: "Typewriter Text",
    inputs: [
      { name: "fontFamily", type: "string" },
      { name: "fontWeight", type: "number" },
      { name: "fontSize", type: "string" },
      { name: "fontColor", type: "string" },
      {
        name: "typewriterStrings",
        type: "list",
        subFields: [{ name: "textString", type: "string" }],
      },
    ],
  });

  // Disable SSR for the Invoca widget, as it uses local storage and will fail hydration checks
  Builder.registerComponent(
    dynamic(() => import("./invoca/InvocaWidget"), {
      ssr: false,
    }),
    {
      name: "Invoca Widget",
      inputs: [
        {
          name: "agentImageUrl",
          type: "string",
          defaultValue:
            "https://enroll.pivothealth.com/assets/8a318b9464278fca41b56cfac8584932.webp",
          required: true,
        },
        {
          name: "contactEmail",
          type: "string",
          defaultValue: "morgan@pivothealth.com",
          required: true,
        },
        {
          name: "presetPhoneNumber",
          type: "string",
          required: false,
        },
        {
          name: "presetPhoneNumberFormatted",
          type: "string",
          required: false,
        },
      ],
    }
  );

  Builder.registerComponent(ButtonBox, {
    name: "Button Box",
    inputs: [
      { name: "imageUrl", type: "string" },
      { name: "imageAltText", type: "string" },
      { name: "content", type: "string" },
      { name: "buttonUrl", type: "string" },
      { name: "buttonText", type: "string" },
    ],
  });

  Builder.registerComponent(ButtonBoxContainer, {
    name: "Button Box Container",
    inputs: [
      {
        name: "boxConfigurations",
        type: "list",
        subFields: [
          { name: "imageUrl", type: "string" },
          { name: "imageAltText", type: "string" },
          { name: "content", type: "string" },
          { name: "buttonUrl", type: "string" },
          { name: "buttonText", type: "string" },
        ],
      },
    ],
  });

  Builder.registerComponent(BeforeYouGoModal, {
    name: "Before You Go Modal",
    inputs: [
      {
        name: "presetPhoneNumberFormatted",
        type: "string",
        required: false,
      },
    ],
  });

  Builder.registerComponent(HeroImage, {
    name: "Hero Image",
  });

  Builder.registerComponent(BuilderH1, {
    name: "Header 1",
    inputs: [{ name: "text", type: "string" }],
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "45px",
    },
  });

  Builder.registerComponent(BuilderH2, {
    name: "Header 2",
    inputs: [{ name: "text", type: "string" }],
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "34px",
    },
  });

  Builder.registerComponent(BuilderH3, {
    name: "Header 3",
    inputs: [{ name: "text", type: "string" }],
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "25px",
    },
  });

  Builder.registerComponent(BuilderH4, {
    name: "Header 4",
    inputs: [{ name: "text", type: "string" }],
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "22px",
    },
  });

  Builder.registerComponent(BuilderH5, {
    name: "Header 5",
    inputs: [{ name: "text", type: "string" }],
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "20px",
    },
  });

  Builder.registerComponent(BuilderH6, {
    name: "Header 6",
    inputs: [{ name: "text", type: "string" }],
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "18px",
    },
  });

  Builder.registerComponent(BuilderText, {
    name: "Text",
    override: true,
    inputs: [{ name: "text", type: "richText" }],
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "16px",
    },
  });

  Builder.register("insertMenu", {
    name: "Header Components",
    items: [
      { item: "Header 1", name: "Header 1" },
      { item: "Header 2", name: "Header 2" },
      { item: "Header 3", name: "Header 3" },
      { item: "Header 4", name: "Header 4" },
      { item: "Header 5", name: "Header 5" },
      { item: "Header 6", name: "Header 6" },
    ],
  });

  Builder.registerComponent(ExperimentText, {
    name: "ExperimentText",
    docsLink: "https://healthcareinc.atlassian.net/wiki/x/Y4CNug",
    friendlyName: "Experiment Text",
    inputs: [
      {
        name: "experimentKey",
        type: "string",
        defaultValue: "",
        friendlyName: 'Experiment Name ("Feature Flag ")',
      },
      {
        name: "defaultVariant",
        type: "string",
        defaultValue: "control",
        friendlyName: "Default Variant",
      },
      {
        name: "variants",
        type: "list",
        defaultValue: [
          {
            variantKey: "control",
            variantContent: "This is the control",
          },
        ],
        subFields: [
          {
            name: "variantKey",
            type: "text",
          },
          {
            name: "variantContent",
            type: "richText",
          },
        ],
      },
    ],
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "16px",
    },
  });

  Builder.registerComponent(ExperimentHeader1, {
    name: "ExperimentHeader1",
    docsLink: "https://healthcareinc.atlassian.net/wiki/x/Y4CNug",
    friendlyName: "Experiment Header 1",
    inputs: builderExperimentHeaderInputs,
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "45px",
    },
  });

  Builder.registerComponent(ExperimentHeader2, {
    name: "ExperimentHeader2",
    docsLink: "https://healthcareinc.atlassian.net/wiki/x/Y4CNug",
    friendlyName: "Experiment Header 2",
    inputs: builderExperimentHeaderInputs,
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "34px",
    },
  });

  Builder.registerComponent(ExperimentHeader3, {
    name: "ExperimentHeader3",
    docsLink: "https://healthcareinc.atlassian.net/wiki/x/Y4CNug",
    friendlyName: "Experiment Header 3",
    inputs: builderExperimentHeaderInputs,
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "25px",
    },
  });

  Builder.registerComponent(ExperimentHeader4, {
    name: "ExperimentHeader4",
    docsLink: "https://healthcareinc.atlassian.net/wiki/x/Y4CNug",
    friendlyName: "Experiment Header 4",
    inputs: builderExperimentHeaderInputs,
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "22px",
    },
  });

  Builder.registerComponent(ExperimentHeader5, {
    name: "ExperimentHeader5",
    docsLink: "https://healthcareinc.atlassian.net/wiki/x/Y4CNug",
    friendlyName: "Experiment Header 5",
    inputs: builderExperimentHeaderInputs,
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "20px",
    },
  });

  Builder.registerComponent(ExperimentHeader6, {
    name: "ExperimentHeader6",
    docsLink: "https://healthcareinc.atlassian.net/wiki/x/Y4CNug",
    friendlyName: "Experiment Header 6",
    inputs: builderExperimentHeaderInputs,
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "18px",
    },
  });

  Builder.registerComponent(ExperimentImage, {
    name: "ExperimentImage",
    docsLink: "https://healthcareinc.atlassian.net/wiki/x/Y4CNug",
    friendlyName: "Experiment Image",
    inputs: [
      {
        name: "experimentKey",
        type: "string",
        defaultValue: "",
        friendlyName: 'Experiment Image ("Feature Flag ")',
      },
      {
        name: "defaultVariant",
        type: "string",
        defaultValue: "control",
        friendlyName: "Default Variant",
      },
      {
        name: "priority",
        type: "boolean",
        friendlyName: "Priority - toggle true if above the fold",
        defaultValue: false,
      },
      {
        name: "variants",
        type: "list",
        defaultValue: [
          {
            variantKey: "control",
            variantContent: "",
            variantAltText: "Default alt text",
          },
        ],
        subFields: [
          {
            name: "variantKey",
            type: "text",
            required: true,
          },
          {
            name: "variantContent",
            type: "file",
            allowedFileTypes: ["jpeg", "jpg", "png", "svg", "webp"],
            required: true,
          },
          {
            name: "variantAltText",
            type: "string",
            required: true,
          },
        ],
      },
    ],
  });

  Builder.registerComponent(VariantBlockWithBuilderChildren, {
    name: "VariantBlock",
    docsLink: "https://healthcareinc.atlassian.net/wiki/x/Y4CNug",
    friendlyName: "Variant Block",
    canHaveChildren: true,
    inputs: [
      {
        name: "experimentKey",
        type: "string",
        defaultValue: "",
        friendlyName: 'Experiment Name ("Feature Flag ")',
      },
      {
        name: "showByDefault",
        type: "boolean",
      },
      {
        name: "variantKey",
        type: "string",
        defaultValue: "",
      },
    ],
    defaultChildren: [
      {
        "@type": "@builder.io/sdk:Element",
        component: {
          name: "Header 1",
          options: { text: "I am a child header block!" },
        },
      },
    ],
    defaultStyles: {
      ...baseDefaultStyle,
      fontSize: "45px",
    },
  });

  Builder.register("insertMenu", {
    name: "Experiment Components",
    items: [
      { item: "ExperimentText", name: "Experiment Text" },
      { item: "ExperimentHeader1", name: "Experiment Header 1" },
      { item: "ExperimentHeader2", name: "Experiment Header 2" },
      { item: "ExperimentHeader3", name: "Experiment Header 3" },
      { item: "ExperimentHeader4", name: "Experiment Header 4" },
      { item: "ExperimentHeader5", name: "Experiment Header 5" },
      { item: "ExperimentHeader6", name: "Experiment Header 6" },
      { item: "ExperimentImage", name: "Experiment Image" },
      { item: "VariantBlock", name: "Variant Block" },
    ],
  });

  Builder.registerComponent(HeaderDropdown, {
    name: "HeaderDropdown",
    friendlyName: "Header Dropdown",
    inputs: [
      {
        name: "title",
        type: "string",
        required: true,
        defaultValue: "Dropdown",
      },
      {
        name: "dropdownOptions",
        friendlyName: "Dropdown Options",
        type: "list",
        defaultValue: [
          {
            text: "Link Text",
            url: "https://pivothealth.com",
          },
        ],
        subFields: [
          {
            name: "text",
            friendlyName: "Text",
            type: "string",
          },
          {
            name: "url",
            friendlyName: "URL",
            type: "string",
          },
        ],
      },
    ],
  });

  Builder.registerComponent(KFFSubsidyCalculator, {
    name: "KFFSubsidyCalculator",
    friendlyName: "KFF Subsidy Calculator",
  });

  const HeaderWithChildren = withChildren(Header);

  Builder.registerComponent(HeaderWithChildren, {
    name: "Header",
    friendlyName: "Header",
    inputs: [
      {
        name: "tabs",
        friendlyName: "Header Links",
        type: "list",
        subFields: [
          {
            name: "label",
            type: "text",
          },
          {
            name: "content",
            type: "uiBlocks",
            defaultValue: [],
          },
          {
            name: "isExpandable",
            helperText:
              "Whether this item is an expandable tab or simply a link",
            type: "boolean",
            defaultValue: true,
          },
          {
            name: "linkURL",
            helperText:
              "Where the item should send the user if it is not an expandable tab",
            type: "string",
          },
        ],
      },
      {
        name: "backgroundColor",
        friendlyName: "Background Color",
        type: "color",
      },
    ],
  });

  // Get tags and categories for WordPress content widget
  // const allTags = await GetTags();
  // const allCategories = await GetCategories();

  // const tagNames = allTags.map((tag) => tag.name);
  // const categoryNames = allCategories.map((category) => category.name);

  Builder.registerComponent(WordPressRelatedContent, {
    name: "WordPress Related Content",
    inputs: [
      {
        name: "widgetTitle",
        type: "text",
      },
      {
        name: "defaultImageURL",
        type: "text",
        helperText:
          "This image should be square. Using other aspect ratios will cause it to be cut off.",
      },
      {
        name: "keyword",
        type: "text",
      },
      {
        name: "tags",
        type: "list",
        subFields: [{ name: "tagName", type: "text" }],
      },
      {
        name: "categories",
        type: "list",
        subFields: [{ name: "categoryName", type: "text" }],
      },
      {
        name: "pageSize",
        type: "number",
        defaultValue: 10,
        required: true,
      },
      {
        name: "showFeaturedImages",
        type: "boolean",
        defaultValue: true,
        required: true,
      },
      {
        name: "wordpressDomain",
        friendlyName: "WordPress Domain",
        type: "text",
        defaultValue: "https://www.pivothealth.com/wp-json/wp/v2",
      },
    ],
  });

  Builder.registerComponent(FAQAccordion, {
    name: "Accordion",
    inputs: [
      {
        name: "accordionItems",
        friendlyName: "Accordion Items",
        type: "list",
        subFields: [
          { name: "title", type: "text", required: true },
          { name: "content", type: "richText" },
        ],
      },
      {
        name: "titleFontSize",
        type: "text",
      },
      {
        name: "titleFontWeight",
        type: "number",
      },
      {
        name: "titleColor",
        type: "text",
      },
      {
        name: "contentFontSize",
        type: "text",
      },
    ],
  });

  Builder.registerComponent(OutlinedAccordion, {
    name: "Outlined Accordion",
    inputs: [
      {
        name: "accordionItems",
        friendlyName: "Accordion Items",
        type: "list",
        subFields: [
          { name: "title", type: "text", required: true },
          { name: "content", type: "richText" },
        ],
      },
      {
        name: "titleFontSize",
        type: "text",
      },
      {
        name: "titleFontWeight",
        type: "number",
      },
      {
        name: "titleColor",
        type: "text",
      },
      {
        name: "contentFontSize",
        type: "text",
      },
    ],
  });

  Builder.registerComponent(HeroDropdown, {
    name: "Hero Dropdown",
    inputs: [
      {
        name: "ctaText",
        helperText: "The text that appears to the left of the dropdown",
        type: "text",
      },
      {
        name: "buttonText",
        type: "text",
      },
      {
        name: "options",
        type: "list",
        subFields: [
          { name: "url", type: "text" },
          { name: "label", type: "text" },
        ],
      },
    ],
  });
}
