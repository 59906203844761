import type { Theme, ThemeOptions } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";

const baseOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 430,
      md: 768,
      lg: 1280,
      xl: 1980,
    },
  },
  spacing: [0, 4, 8, 12, 16, 24, 32, 40, 48, 60],
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
    },
  },
};

declare module "@mui/material/styles" {
  interface Palette {
    tertiary: Palette["primary"];
    quaternary: Palette["primary"];
    economy: Palette["primary"];
    choice: Palette["primary"];
    standard: Palette["primary"];
    deluxe: Palette["primary"];
    backgroundBlue: Palette["primary"];
    darkText: Palette["primary"];
  }
  interface PaletteOptions {
    tertiary: PaletteOptions["primary"];
    quaternary: PaletteOptions["primary"];
    economy: PaletteOptions["primary"];
    choice: PaletteOptions["primary"];
    standard: PaletteOptions["primary"];
    deluxe: PaletteOptions["primary"];
    backgroundBlue: PaletteOptions["primary"];
    darkText: PaletteOptions["primary"];
  }
}

/** Base DTC theme containing fonts and color palette */
export const dtcTheme: Theme = createTheme({
  ...baseOptions,
  typography: {
    fontFamily: "Source Sans Pro, sans-serif",
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#0086E7",
      light: "#199AF7",
      dark: "#0E7087",
    },
    secondary: {
      main: "#59BAFF",
      dark: "#38ACFF",
      light: "#82DFFF",
    },
    tertiary: {
      main: "#FFE461",
      dark: "#FED047",
      light: "#FFEC92",
    },
    quaternary: {
      main: "#F9690E",
      dark: "#E56310",
      light: "#FE8A41",
    },
    success: {
      main: "#73DA79",
    },
    error: {
      main: "#FF4536",
    },
    background: {
      default: "#FFFFFF",
    },
    divider: "#D2D2D2",
    text: {
      secondary: "#303952",
    },
    economy: {
      main: "#FF8B3A",
    },
    choice: {
      main: "#F6C547",
    },
    standard: {
      main: "#98B6D9",
    },
    deluxe: {
      main: "#D5B188",
    },
    backgroundBlue: {
      main: "#ECF7FF",
    },
    darkText: {
      main: "#4A4A4A",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowX: "hidden",
        },
        a: {
          textDecoration: "none",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          transition: "none",
          backgroundColor: "#ecf7ff !important",
          "&:hover": {
            transition: "none",
            backgroundColor: "#ecf7ff !important",
          },
          "&$focused": {
            transition: "none",
            backgroundColor: "#ecf7ff !important",
          },
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          ":focus": {
            backgroundColor: "#fff !important",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
          fontSize: "20px",
          fontFamily: "proxima-nova, sans-serif",
          fontWeight: 600,
          backgroundColor: "#F9690E",
          height: "100%",
          minHeight: "53px",
          "&:hover": {
            backgroundColor: "#F97D0E",
            boxShadow: "none",
          },
        },
      },
    },
  },
});
